import copilotImage from '../../../lib/assets/co-pilot.png';
import dataLakeImage from '../../../lib/assets/digital-bg.webp';
import girlImage from '../../../lib/assets/girl.jpg';
import vodImage from '../../../lib/assets/vod.png';

const productItems = [
	{
		id: 'video-base-package',
		group: 'vod',
		image: vodImage,
		title: 'Video Intelligence on-demand',
		subtitle: '',
		subscriptionName: 'Video intelligence on demand ',
		path: '/app/dashboard/video-intelligence-on-demand',
		borderColor: '#376481',
	},
	{
		id: 'datalake',
		group: 'datalake',
		image: dataLakeImage,
		title: 'Video Data Lake',
		subscriptionName: 'Data Lake',
		path: '/app/dashboard/video-data-lake',
		borderColor: '#376481',
	},
	{
		id: 'co-pilot',
		group: 'copilot',
		subscriptionName: 'Video Insights Co-Pilot',
		image: copilotImage,
		title: 'Video Insights Co-Pilot',
		path: '/app/dashboard/video-insights-co-pilot',
		borderColor: '#376481',
	},
	{
		id: 'report',
		group: 'report',
		subscriptionName: 'Integrity Report',
		image: girlImage,
		title: 'Influencer Integrity Report',
		path: '/app/dashboard/creators',
		borderColor: '#8C5F4A',
	},
];

export { productItems };
