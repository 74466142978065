import { Avatar, Space } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import Link from 'antd/es/typography/Link';

import { CopilotHistoryPageItem } from '@/api/batch_process';
import placeholderImage from '@/lib/assets/placeholder.png';
import prepareDate from '@/lib/helpers/prepareDate';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

import Platforms from '../creators/platforms';

const columns = [
	{
		title: 'id',
		dataIndex: 'id',
		key: 'id',
		hidden: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			return <p title={'' + record?.id}>{record.title}</p>;
		},
	},

	{
		title: 'Video',
		dataIndex: 'title',
		key: 'title',
		width: 200,
		ellipsis: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			const preparedTitle =
				(record?.title || record?.description)?.length > 90
					? (record?.title || record?.description).slice(0, 90) + '...'
					: record?.title || record?.description;

			return (
				<Space size={[4, 1]}>
					<Avatar
						src={
							record?.thumbnail
								? prepareImageSrc(record?.thumbnail)
								: placeholderImage
						}
						alt={`${record?.platform} avatar`}
					/>{' '}
					<Link href={record?.url} ellipsis target="_blank">
						{preparedTitle}
					</Link>
				</Space>
			);
		},
	},
	{
		title: 'Platform',
		dataIndex: 'platform',
		key: 'platform',
		// sorter: true,
		width: 50,
		// flex: true,
		// hidden: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			return <Platforms platform={record?.platform} />;
		},
	},
	{
		title: 'Date/time processed',
		dataIndex: 'processedDate',
		key: 'processedDate',
		sorter: false,
		sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
		width: 100,
		// flex: true,
		ellipsis: true,
		// hidden: true,
		render: (_: any, record: CopilotHistoryPageItem) => {
			return (
				<>
					{record?.createdAt ? prepareDate('' + record?.createdAt) : 'no date'}
				</>
			);
		},
	},
];

export { columns };
