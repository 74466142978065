export function formatSecondsToTime(
  seconds: number,
  withSeconds = false
): string {
  if (seconds === 0) {
    return "0";
  }

  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsAmount =
    (withSeconds && hours < 1) || days + hours + minutes < 1
      ? Math.floor(seconds % 60)
      : 0;

  const daysText = days > 0 ? `${days} day${days !== 1 ? "s" : ""}` : "";
  const hoursText = hours > 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : "";
  const minutesText =
    minutes > 0 ? `${minutes} minute${minutes !== 1 ? "s" : ""}` : "";
  const secondsText =
    secondsAmount > 0
      ? `${secondsAmount} second${secondsAmount !== 1 ? "s" : ""}`
      : "";

  const parts =
    days > 0
      ? [daysText, hoursText].filter(Boolean)
      : [daysText, hoursText, minutesText, secondsText].filter(Boolean);

  return parts.join(" ");
}
