import { useCallback, useEffect, useRef, useState } from 'react';

import ReactPlayer from 'react-player';

import { TActiveMenuTab } from '@/components/drawers/ViewCopilotVideoDetailsDrawer';

const useTabVideoPlayer = (
	activeTab: TActiveMenuTab,
	currectTab: TActiveMenuTab,
) => {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const playerRef = useRef<ReactPlayer>(null);
	const [isPlayerVisible, setIsPlayerVisible] = useState(false);
	let timerId1: NodeJS.Timeout | null = null;
	let timerId2: NodeJS.Timeout | null = null;

	useEffect(() => {
		if (activeTab !== currectTab && isPlayerVisible) {
			setIsPlayerVisible(false);
		}

		return () => {
			setIsPlayerVisible(false);
			timerId1 && clearTimeout(timerId1);
			timerId2 && clearTimeout(timerId2);
		};
	}, [activeTab]);

	const seekToTime = useCallback(
		(seconds: number) => {
			if (!isPlayerVisible) {
				setIsPlayerVisible(true);
				timerId2 = setTimeout(
					() =>
						wrapperRef?.current &&
						wrapperRef?.current?.scrollIntoView({
							block: 'center',
							behavior: 'smooth',
						}),
					300,
				);
				timerId1 = setTimeout(() => {
					playerRef.current && playerRef.current.seekTo(seconds, 'seconds');
				}, 1000);

				return;
			}

			wrapperRef?.current &&
				wrapperRef?.current?.scrollIntoView({
					block: 'start',
					behavior: 'smooth',
				});
			playerRef.current && playerRef.current.seekTo(seconds, 'seconds');
		},
		[isPlayerVisible, timerId1, timerId2],
	);

	return { wrapperRef, playerRef, isPlayerVisible, seekToTime };
};

export default useTabVideoPlayer;
