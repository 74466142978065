import { useState } from 'react';

import {
	CaretDownOutlined,
	CaretUpOutlined,
	LikeOutlined,
} from '@ant-design/icons';
import { Button, Flex, List, Typography } from 'antd';

import { formatNumber } from '@/lib/helpers/formatNumber';
import getCommentSentiment from '@/lib/helpers/getCommentSentiment';
import getSentimentBadge from '@/lib/helpers/getSentimentBadgeColor';
import { prepareComment } from '@/lib/helpers/prepareComment';
import prepareDate from '@/lib/helpers/prepareDate';

import styles from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import { mockData } from '../data';

type TProps = {
	item: any;
	data?: any;
	activeTab: TActiveMenuTab;
};

type TCommentsData = { comments: typeof mockData.comments };
type TTldrData = typeof mockData.videoData.Comments;

export default function Comments({ activeTab, item }: TProps) {
	const [collapsed, setCollapsed] = useState(false);

	const commentsData: any = item?.comments;
	const tldrData: TTldrData = item?.videoData?.Comments;

	if (activeTab !== 'comments') {
		return null;
	}

	return (
		<Flex vertical gap={4} align="flex-start" style={{ paddingLeft: '0.5rem' }}>
			<>
				{tldrData && tldrData?.Summary && (
					<Flex vertical style={{ marginBottom: '0.5rem' }}>
						<Typography.Text style={{ fontWeight: 500 }}>
							<span
								style={{ color: 'gray', marginRight: '4px', fontWeight: 600 }}
							>
								TLDR
							</span>
							{tldrData?.Summary}

							{tldrData?.Extracts?.length > 0 && (
								<Button
									type="text"
									size="small"
									icon={collapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
									onClick={() => setCollapsed((cur) => !cur)}
									style={{ marginLeft: '0.5rem' }}
									aria-label="Toggle expand/collapse"
								></Button>
							)}
						</Typography.Text>

						{tldrData?.Extracts?.length > 0 && (
							<>
								{!collapsed && (
									<>
										<List
											itemLayout="horizontal"
											className={styles.keyInsights}
											dataSource={tldrData?.Extracts ?? []}
											renderItem={(item: any) => (
												<List.Item>
													<List.Item.Meta
														avatar={
															item?.Sentiment && (
																<span
																	style={{
																		width: '10px',
																		height: '10px',
																		borderRadius: '50%',
																		display: 'block',
																	}}
																>
																	{getSentimentBadge(item?.Sentiment)}
																</span>
															)
														}
														title={
															<span
																style={{
																	color: '#000',
																	fontWeight: 500,
																}}
															>
																{item?.Comment}
															</span>
														}
													/>
												</List.Item>
											)}
										/>
									</>
								)}
							</>
						)}
					</Flex>
				)}

				<Flex
					gap={40}
					justify="flex-start"
					align="center"
					className={styles.commentsWrapper}
				>
					<List
						itemLayout="horizontal"
						className={styles.keyInsights}
						dataSource={commentsData ?? []}
						renderItem={(item: any) => (
							<List.Item>
								<List.Item.Meta
									avatar={
										item?.sentiment && (
											<span
												style={{
													width: '10px',
													height: '10px',
													borderRadius: '50%',
													display: 'block',
												}}
											>
												{getCommentSentiment(item?.sentiment)}
											</span>
										)
									}
									title={
										<span
											style={{
												color: '#000',
												fontWeight: 500,
											}}
										>
											<a href={item?.userLink} target="_blank">
												{item?.userLink?.split('.com/')?.[1]}{' '}
											</a>
											<span
												style={{
													color: 'darkgray',
													fontWeight: 400,
													fontSize: '14px',
												}}
											>
												{prepareDate(item?.dateTime)}
											</span>
										</span>
									}
									description={
										<span
											style={{
												color: '#000',
												fontWeight: 400,
											}}
										>
											{prepareComment(item?.text)}
										</span>
									}
								/>
								{item?.likes >= 0 && (
									<Flex gap={4} style={{ color: 'gray', paddingLeft: '1rem' }}>
										<LikeOutlined /> {formatNumber(item?.likes)}
									</Flex>
								)}
							</List.Item>
						)}
					/>
				</Flex>
			</>
		</Flex>
	);
}
