import React, { useContext, useEffect } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
	Checkbox,
	CheckboxProps,
	Flex,
	Form,
	Input,
	Modal,
	Spin,
	Tabs,
} from 'antd';
import { Button, List } from 'antd';
import type { TabsProps } from 'antd';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { useNavigate } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import { prepareAmountNumber } from '@/lib/helpers/numFormat';
import client from '@/lib/http/client';
import { PageContext } from '@/providers/PageContextProvider';

import NewProductSubscription from './NewProductSubscription';
import { subsriptionIcons } from './SubscriptionIcons';
import {
	ClientExtendedSubscription,
	ClientSubscription,
} from './subscriptionType';
import UnsubscribeModal from './unsubscribeModal';

const CurentSubscription = ({
	subscriptions,
	refetchSubscriptions,
}: {
	subscriptions: ClientSubscription[];
	refetchSubscriptions: () => any;
}) => {
	const navigate = useNavigate();
	const [openModal, setOpenModal] = React.useState(false);

	const [topUpButtonDisbled, setTopUpButtonDisbled] = React.useState(true);
	const [TopUpSubscrition, setTopUpSubscrition] =
		React.useState<ClientSubscription | null>(null);
	const [topUpCount, setTopUpCount] = React.useState(0);

	const {
		openDrawer,
		setPageTitle,
		setIsCreditsCounterVisible,
		openModal: openModalUnsub,
	} = useContext(PageContext);
	const { setUnsubscribeSubscription } = useContext(SubscriptionContext);
	// const unsubscribe = async () => {
	// 	return await client.post(`/payment/unsubscribe`, {
	// 		subscription_id: TopUpSubscrition?.subscription_id,
	// 	});
	// };

	// const { data, isError, error, isFetching, refetch } = useQuery({
	// 	queryKey: ['unsubscribe'],
	// 	queryFn: unsubscribe,
	// 	enabled: false,
	// });

	useEffect(() => {
		setPageTitle('Subscriptions');
		setIsCreditsCounterVisible(false);

		return () => {
			setPageTitle('');
		};
	}, []);

	const showModal = () => {
		setOpenModal(true);
	};

	const handleCancel = () => {
		setOpenModal(false);
		setTopUpSubscrition(null);
		setTopUpCount(0);
	};
	const [form] = Form.useForm();

	const handleOk = async () => {
		navigate('/app/subscribe-top-up', {
			state: { TopUpSubscrition, topUpCount },
		});
	};

	const handleUnsubscribe = async () => {
		// await refetch();
		refetchSubscriptions();
	};

	const openSubscriptionConfigurationDrawer = (
		subscription: ClientExtendedSubscription,
	) => openDrawer('subscription-configuration', subscription);

	const tabsItems: TabsProps['items'] = [
		{
			key: 'current-subscriptions',
			label: 'Current subscriptions',
			children: (
				<List
					style={{ marginBottom: '124px' }}
					itemLayout="vertical"
					size="large"
					dataSource={subscriptions}
					locale={{ emptyText: "You don't have any subscription yet..." }}
					renderItem={(subscription: ClientExtendedSubscription) => {
						const IconComponent = subsriptionIcons[subscription.subscription_id]
							? subsriptionIcons[subscription.subscription_id]
							: subsriptionIcons[1];
						return (
							<List.Item
								key={subscription.id}
								actions={[]}
								extra={
									// subscription?.type === 'video' ? null :
									<IconComponent />
								}
								style={{
									marginTop: '24px',
									padding: '16px',
									pointerEvents: subscription.active ? 'auto' : 'none',
									opacity: subscription.active ? 1 : 0.6,
									border: '1px solid #f0f0f0',
									borderRadius: '5px',
									fontSize: '16px',
								}}
							>
								<List.Item.Meta
									title={
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
											}}
										>
											<h3
												style={{
													width: 'max-content',
													maxWidth: '300px',
													textAlign: 'left',
													marginRight: '0.5rem',
												}}
											>
												{subscription.subscription_name}
											</h3>
											{subscription.active && (
												<Flex vertical gap={8}>
													<div style={{ width: 'max-content' }}>
														{' '}
														{subscription?.type === 'video' ? (
															<Button
																icon={
																	<SettingOutlined
																		style={{ fontSize: '12px' }}
																	/>
																}
																onClick={() =>
																	openSubscriptionConfigurationDrawer(
																		subscription,
																	)
																}
															>
																Configuration
															</Button>
														) : (
															<Button
																// icon={<EditOutlined style={{ fontSize: '12px' }} />}
																onClick={() => {
																	// setModalTags(value.tags[0]);
																	// setTagsReportId(value.report_id);
																	setTopUpSubscrition(subscription);
																	showModal();
																}}
															>
																Top-up
															</Button>
														)}
														{subscription.status === 'active' && (
															<Button
																style={{
																	marginLeft: '10px',
																	color: 'red',
																}}
																// icon={<EditOutlined style={{ fontSize: '12px' }} />}
																onClick={() => {
																	setTopUpSubscrition(subscription);
																	setUnsubscribeSubscription(subscription);
																	openModalUnsub('unsubscribe');
																}}
															>
																Unsubscribe
															</Button>
														)}
													</div>
													{subscription?.type === 'video' && (
														<Flex style={{ width: '100%' }} justify="center">
															<a
																href="#"
																target="_blank"
																style={{ fontSize: '14px' }}
															>
																Subscription documentation
															</a>
														</Flex>
													)}
												</Flex>
											)}
										</div>
									}
								/>
								<p>{subscription.description}</p>
								<div
									style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
								>
									<p>
										Billing Day of month:{' '}
										{subscription.day_of_month_period_start}
									</p>
									<div style={{ display: 'flex', gap: '20px' }}>
										<p>
											{' '}
											Total:{' '}
											{prepareAmountNumber(
												subscription.item_count_per_month,
											)}{' '}
										</p>
										<p>
											{' '}
											Used: {prepareAmountNumber(subscription.item_count_used)}
										</p>
									</div>

									<p>
										{' '}
										Subscription date:{' '}
										{new Date(
											subscription.subscription_start_date,
										).toLocaleDateString('en-GB', {
											day: '2-digit',
											month: 'short',
											year: 'numeric',
										})}
									</p>

									{subscription.status === 'active' ? (
										<p>
											Next billing date:{' '}
											{new Date(
												subscription.subscription_end_date,
											).toLocaleDateString('en-GB', {
												day: '2-digit',
												month: 'short',
												year: 'numeric',
											})}
										</p>
									) : (
										<div style={{ color: 'red' }}>
											<p>Subscription canceled</p>
											<p>
												Credits expierd date:{' '}
												{new Date(
													subscription.subscription_end_date,
												).toLocaleDateString('en-GB', {
													day: '2-digit',
													month: 'short',
													year: 'numeric',
												})}
											</p>
										</div>
									)}

									{subscription?.type === 'video' && (
										<>
											{typeof subscription?.additional_videos_allowed ===
												'boolean' && (
												<>
													<p>
														Additional videos allowed:{' '}
														{subscription?.additional_videos_allowed
															? 'Yes'
															: 'No'}
													</p>
													{subscription.additional_videos_amount && (
														<p>
															Amount:{' '}
															{prepareAmountNumber(
																subscription.additional_videos_amount,
															)}
														</p>
													)}
												</>
											)}
										</>
									)}

									{subscription?.type === 'video' && (
										<>
											{subscription?.api_key ? (
												<>
													<Flex align="center">
														<b>API access token</b>
													</Flex>
													<Flex justify="space-between" align="center">
														<b>{subscription?.api_key?.slice(0, 16)}...</b>
														<Button
															type="link"
															onClick={() =>
																openSubscriptionConfigurationDrawer(
																	subscription,
																)
															}
														>
															Change
														</Button>
													</Flex>
												</>
											) : (
												<>
													<Flex align="center">
														<b>You don't have API access token</b>
													</Flex>
													<Flex justify="space-between" align="flex-end">
														<Button
															// type="text"
															onClick={() =>
																openSubscriptionConfigurationDrawer(
																	subscription,
																)
															}
														>
															Get token
														</Button>
													</Flex>
												</>
											)}
										</>
									)}
								</div>
								{!!subscription.sum_count_topup && (
									<div>
										<h4>Top Up</h4>
										<div style={{ display: 'flex', gap: '20px' }}>
											<p>Total: {subscription.sum_count_topup} </p>
											<p>
												Used:{' '}
												{(subscription.sum_in_process_topup || 0) +
													(subscription.sum_used_count_topup || 0)}{' '}
											</p>
										</div>
									</div>
								)}
							</List.Item>
						);
					}}
				/>
			),
		},
		{
			key: 'new-product-subscription',
			label: 'New product subscription',
			children: (
				<NewProductSubscription
					currentSubscriptions={subscriptions as ClientExtendedSubscription[]}
				/>
			),
		},
	];

	return (
		<div style={{ width: '100%' }}>
			<Spin spinning={false} delay={500}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'flex-start',
					}}
				>
					<Tabs
						defaultActiveKey={tabsItems[0].key}
						items={tabsItems}
						size="large"
						style={{ width: '100%', minWidth: '920px' }}
					/>
					{/* <h2>Current Subscriptions</h2> */}
					{/* If none of the subscriptions are active, show the "New Subscription" button */}
					{!subscriptions.some((subscription) => subscription.active) && (
						<Button
							type="primary"
							size="large"
							onClick={() => navigate('/app/dashboard/subscription-plans')}
						>
							New Subscription
						</Button>
					)}
				</div>

				<Modal
					destroyOnClose
					open={openModal}
					title={TopUpSubscrition?.subscription_name}
					onOk={handleOk}
					onCancel={handleCancel}
					footer={[
						<Button key="back" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							disabled={topUpButtonDisbled}
							onClick={handleOk}
						>
							Continue
						</Button>,
					]}
				>
					<p>Top-up unit cost is ${TopUpSubscrition?.top_up_eur} </p>
					<div
						style={{
							width: 'fit-content',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<label>Count required</label>
						<Form>
							<Form.Item
								label="Value"
								name="numberValue"
								rules={[
									{
										validator: (_, value) => {
											const numberValue = parseInt(value, 10);
											if (value !== numberValue.toString()) {
												setTopUpButtonDisbled(true);
												return Promise.reject('Value should be an integer');
											}
											return numberValue >= 0 && numberValue <= 10000000
												? Promise.resolve()
												: (setTopUpButtonDisbled(true),
												  Promise.reject(
														'Value should be an integer between 0 and 10000000',
												  ));
										},
									},
								]}
								validateTrigger="onChange"
							>
								<Input
									type="number"
									max={10000000}
									min={0}
									placeholder="Choose count of top-up"
									required
									// onKeyDown={(event) => event.preventDefault()}
									onChange={(value) => {
										setTopUpCount(value.target.valueAsNumber);
										setTopUpButtonDisbled(false);
									}}
								/>
							</Form.Item>
						</Form>

						<div>
							Chargable coast:
							{topUpCount
								? (TopUpSubscrition?.top_up_eur ?? 0) * topUpCount
								: 0}{' '}
							$
						</div>
					</div>

					<p>
						Note: Any top-up purchased must be used by the end of the current
						billing period{' '}
						<span>
							{' '}
							{TopUpSubscrition &&
								new Date(
									TopUpSubscrition.subscription_end_date,
								).toLocaleDateString('en-GB', {
									day: '2-digit',
									month: 'short',
									year: 'numeric',
								})}
						</span>
						. Any top-up not used by this date will lapse.
					</p>
				</Modal>
			</Spin>
		</div>
	);
};

export default CurentSubscription;
