export default function getSentimentBadge(Sentiment: string) {
  switch (Sentiment?.toLowerCase()) {
    case "positive":
      return "🟢";

    case "neutral":
      return "⚪";

    case "negative":
      return "🔴";

    default:
      return "⚫";
  }
}
