import React from 'react';

interface IProps {
	text: string;
	searchTerm: string;
	className?: string; // Optional: CSS class for highlighted text
}

export default function TranscriptionText({
	text,
	searchTerm,
	className,
}: IProps) {
	if (!text) return null;

	if (!searchTerm)
		return (
			<span>
				{text.split('\n').map((line, index) => (
					<React.Fragment key={index}>
						{line}
						<br />
					</React.Fragment>
				))}
			</span>
		);

	const escapedSearchQuery = searchTerm?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	const regex = new RegExp(`(${escapedSearchQuery})`, 'gi');

	const parts = text?.split(regex);

	return (
		<div>
			{parts?.map((part, index) =>
				part?.match(regex) ? (
					<mark key={index}>{part}</mark>
				) : (
					part?.split('\n')?.map((line, idx) => (
						<React.Fragment key={`${index}-${idx}`}>
							{line}
							{idx < part?.split('\n')?.length - 1 && <br />}
						</React.Fragment>
					))
				),
			)}
		</div>
	);
}
