import { Button, List } from 'antd';

import getSentimentBadge from '@/lib/helpers/getSentimentBadgeColor';
import getTimeInSeconds from '@/lib/helpers/getTimeInSeconds';

type TProps = {
	item: {
		Name: string;
		Sentiment: string;
		Summary: string;
		Extract: string;
		Timestamps: string[];
	};
	videoSeekToTime: (seconds: number) => void;
};

export default function BrandEntityCollapsableItem({
	item,
	videoSeekToTime,
}: TProps) {
	const badge = getSentimentBadge(item?.Sentiment);

	return (
		<List.Item style={{ marginBottom: '0.5rem' }} key={item?.Name}>
			<List.Item.Meta
				avatar={
					badge ? (
						<span style={{ display: 'block', marginTop: '5px' }}>{badge}</span>
					) : null
				}
				title={
					<>
						<span
							style={{
								color: '#000',
								marginRight: '8px',
							}}
						>
							{item?.Name}
						</span>

						{item?.Timestamps?.map((timestamp, idx) => {
							const seconds = getTimeInSeconds(timestamp);

							if (seconds) {
								return (
									<Button
										key={'brand-timestamp-' + timestamp}
										type="link"
										style={{
											padding: '2px 2px',
											marginRight: '4px',
										}}
										onClick={() => videoSeekToTime(seconds)}
									>
										{timestamp}
									</Button>
								);
							}
							return;
						})}
					</>
				}
				description={
					<span
						style={{
							color: '#000',
							fontWeight: 400,
						}}
					>
						{item?.Summary}
					</span>
				}
			/>
		</List.Item>
	);
}
