import ReactPlayer from 'react-player';

import { CDN_URL } from '@/const';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

type TProps = {
	url: string;
	playerRef: any;
	preview?: string;
};

export default function VideoPlayer({
	url = '',
	playerRef,
	preview = '',
}: TProps) {
	return (
		<ReactPlayer
			ref={playerRef}
			// playing={playing}
			// onProgress={(progress) => setPlayedTime(progress.playedSeconds)}
			// onReady={onReady}
			// onReady={(player) => {
			// console.log("ready event");
			//
			// playerRef.current = player;
			// }}
			url={url}
			// url={isLocalMode ? mockVideo : url}
			controls
			config={{
				file: {
					attributes: {
						controlsList: 'nofullscreen',
					},
				},
			}}
			width={'450px'}
			height={'auto'}
			style={{
				maxHeight: '350px',
			}}
			// height={extensionWidth > 360 ? 400 : "auto"}
		/>
	);
	// if (preview?.length > 0) {
	// 	return (
	// 		<ReactPlayer
	// 			url={url}
	// 			controls
	// 			config={{
	// 				file: {
	// 					attributes: {
	// 						controlsList: 'nofullscreen',
	// 					},
	// 				},
	// 			}}
	// 			light={
	// 				<img
	// 					src={
	// 						preview?.startsWith('http')
	// 							? preview
	// 							: CDN_URL + prepareImageSrc(preview)
	// 					}
	// 					alt="Video thumbnail"
	// 				/>
	// 			}
	// 			// width={300}
	// 			// height={224}
	// 		/>
	// 	);
	// }

	// return <ReactPlayer url={url} controls />;
}
