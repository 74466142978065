import { Dispatch } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Flex, Input, Typography } from 'antd';

import style from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import TranscriptionText from '../TranscriptionText';
import { mockData } from '../data';

interface TProps {
	data?: any;
	search: string;
	setSearch: Dispatch<string>;
	activeTab: TActiveMenuTab;
	item: any;
}

type TVideoData = typeof mockData.videoData;

export default function Transcript({
	search,
	setSearch,
	activeTab,
	item,
}: TProps) {
	const videoData: TVideoData = item?.videoData;

	if (activeTab !== 'transcript') {
		return null;
	}

	return (
		<Flex vertical className={style.scrollableWrapper}>
			<>
				{videoData?.transcription?.length > 1 && (
					<Flex className={style.searchWrapper} gap={8} align="center">
						<Typography.Text style={{ width: 'max-content', minWidth: '50px' }}>
							Search
						</Typography.Text>
						<Input
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Type to search"
						/>
						<SearchOutlined className={style.searchIcon} />
					</Flex>
				)}
				{videoData?.transcription?.length > 1 ? (
					<TranscriptionText
						text={videoData?.transcription}
						searchTerm={search}
					/>
				) : (
					<span style={{ color: 'gray' }}>n/a</span>
				)}
			</>
		</Flex>
	);
}
