export default function getCommentSentiment(sentiment: string) {
  switch (sentiment) {
    case "positive":
      return "💖";

    case "negative":
      return "😡";

    case "neutral":
      return "😐";

    default:
      return "";
  }
}
