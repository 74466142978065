import { useContext, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import { subscription } from '@/layouts/subscription/subscriptionType';
import client from '@/lib/http/client';
import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

import { productItems } from '../../../../layouts/subscription/NewProductSubscription/data';
import copilotImage from '../../../../lib/assets/co-pilot.png';
import dataLakeImage from '../../../../lib/assets/digital-bg.webp';
import girlImage from '../../../../lib/assets/girl.jpg';
import vodImage from '../../../../lib/assets/vod.png';

// const menuItems = [
// 	{
// 		id: 'report',
// 		image: girlImage,
// 		title: 'Influencer Integrity Report',
// 		searchTitle: 'Integrity Report',
// 		path: '/app/dashboard/creators',
// 		borderColor: '#8C5F4A',
// 	},
// 	{
// 		id: 'datalake',
// 		image: dataLakeImage,
// 		title: 'Video Data Lake',
// 		searchTitle: 'Video Data Lake',
// 		path: '/app/dashboard/video-data-lake',
// 		borderColor: '#376481',
// 	},
// 	{
// 		id: 'video',
// 		image: vodImage,
// 		title: 'Video Intelligence on demand',
// 		searchTitle: 'Video Intelligence on demand',
// 		path: '/app/dashboard/video-intelligence-on-demand',
// 		borderColor: '#376481',
// 	},
// 	{
// 		id: 'co-pilot',
// 		image: copilotImage,
// 		title: 'Video Insights Co-Pilot',
// 		searchTitle: 'Video Insights Co-Pilot',
// 		path: '/app/dashboard/video-insights-co-pilot',
// 		borderColor: '#376481',
// 	},
// ];

export default function ProductMainMenuPage() {
	const { subscriptions } = useContext(SubscriptionContext);
	const {
		setIsCreditsCounterVisible,
		setPageTitle,
		openDrawer,
		setSelectedSubscriptionsType,
	} = useContext(PageContext);

	const fetchSubscriptions = async () => {
		return await client.get<subscription[]>(`/subscriptions`);
	};

	const { data: Allsubscriptions, isLoading } = useQuery({
		// queryKey: ['subscriptions'],
		queryFn: async () => await fetchSubscriptions(),
		cacheTime: 0,
	});

	useEffect(() => {
		setIsCreditsCounterVisible(false);
		setPageTitle('Product main menu');
		return () => {
			setIsCreditsCounterVisible(true);
			setPageTitle('');
		};
	}, []);

	return (
		<div>
			<ul className={style.buttonLinksWrapper}>
				{productItems.map((item, idx) => {
					const isInSubscription = !!subscriptions?.find((sub) =>
						sub?.type?.toLowerCase()?.includes(item?.group?.toLowerCase()),
					);

					if (!isInSubscription) {
						return (
							<Button
								type="link"
								className={cn(style.link, style.button, style.gray)}
								key={item.id + idx}
								style={{ borderColor: item.borderColor }}
								onClick={() => {
									openDrawer('view-product-subscription', {
										subscriptionName: item.title,
										id: item.id,
									});
									setSelectedSubscriptionsType(
										Allsubscriptions?.data
											? Allsubscriptions.data.filter(
													(allSubs) => allSubs.type === item.group,
											  )
											: [],
									);
								}}
							>
								{' '}
								<div
									className={style.imageWrapper}
									style={
										item.id === 'report'
											? {
													borderRight: `1px solid ${item.borderColor}`,
													// maxWidth: '150px',
											  }
											: { borderRight: `1px solid ${item.borderColor}` }
									}
								>
									<img
										src={item.image}
										className={style.image}
										style={
											item.id === 'video'
												? {
														transform:
															'translate(7px,7px) scale(1.4) rotateZ(-45deg)',
														objectFit: 'contain',
												  }
												: item.id === 'co-pilot'
												? {
														transform: 'scale(0.75)',
												  }
												: {}
										}
									/>
								</div>
								<div className={style.textWrapper}>
									<p
										className={style.text}
										style={item.id === 'datalake' ? { maxWidth: '120px' } : {}}
									>
										{item.title}
									</p>
								</div>
							</Button>
						);
					}

					return (
						<Link
							to={item.path}
							className={cn(style.link)}
							key={item.id + idx}
							style={{ borderColor: item.borderColor }}
						>
							<div
								className={style.imageWrapper}
								style={
									item.id === 'report'
										? {
												borderRight: `1px solid ${item.borderColor}`,
												// maxWidth: '150px',
										  }
										: { borderRight: `1px solid ${item.borderColor}` }
								}
							>
								<img
									src={item.image}
									className={style.image}
									style={
										item.id === 'video'
											? {
													transform:
														'translate(7px,7px) scale(1.4) rotateZ(-45deg)',
													objectFit: 'contain',
											  }
											: item.id === 'co-pilot'
											? {
													transform: 'scale(0.75)',
											  }
											: {}
									}
								/>
							</div>

							<div className={style.textWrapper}>
								<p
									className={style.text}
									style={item.id === 'datalake' ? { maxWidth: '120px' } : {}}
								>
									{item.title}
								</p>
							</div>
						</Link>
					);
				})}
			</ul>
		</div>
	);
}
