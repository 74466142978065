export default function getTimeInSeconds(timeString: string): number | null {
  if (!timeString) {
    return null;
  }

  const timeParts = timeString?.split(":")?.map(Number); // Split and convert each part to a number

  if (timeParts.length === 3) {
    // Format is "hh:mm:ss"
    const [hours, minutes, seconds] = timeParts;
    return hours * 3600 + minutes * 60 + seconds;
  } else if (timeParts.length === 2) {
    // Format is "mm:ss"
    const [minutes, seconds] = timeParts;
    return minutes * 60 + seconds;
  } else {
    // throw new Error(

    console.log(
      "Invalid time format. Must be in 'hh:mm:ss' or 'mm:ss' format."
    );
    return null;
  }
}
