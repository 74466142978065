import prepareApiUrl from '@/lib/helpers/prepareApiUrl';

const tempStorageUrl = (
	process.env.REACT_APP_CDN_STORAGE ?? 'https://storage.googleapis.com/svcdn'
)?.toString();

const CDN_URL = tempStorageUrl.endsWith('/')
	? tempStorageUrl.slice(0, -1)
	: tempStorageUrl;

const NODE_API_URL = prepareApiUrl(process.env.REACT_APP_API_URL);

const GO_API_URL = prepareApiUrl(process.env.REACT_APP_SV_GO_API_URL);

const WHISPER_ACTIONS = [
	{
		value: 'transcribe',
		label: 'Transcribe',
		enabled: true,
		// required: ['audio-quality'],
		required: [],
		group: 'transcribe',
	},
	{
		value: 'translate',
		label: 'Translate',
		enabled: true,
		// required: ['transcribe', 'ner'],
		required: [],
		group: 'transcribe',
	},
	// #region TextAnalytics
	{
		value: 'ner',
		label: 'Entity recognition',
		enabled: true,
		required: ['transcribe'],
		group: 'text-analytics',
	},
	{
		value: 'toxicity',
		label: 'Toxicity',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'iab-categories',
		label: 'IAB categories',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'personality',
		label: 'Personality',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'brand-safety',
		label: 'Brand safety',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'leaning',
		label: 'Leaning',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'tonality',
		label: 'Tonality',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'keywords',
		label: 'Keywords',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'direction-focus',
		label: 'Direction focus',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'section-index-creation',
		label: 'Section index creation',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'section-summary',
		label: 'Section summary',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'overall-summary',
		label: 'Overall summary',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'key-topics',
		label: 'Key topics',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'key-takeaways',
		label: 'Key takeaways',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	{
		value: 'gold-brand-label-sampling',
		label: 'Gold brand-label sampling',
		enabled: true,
		group: 'text-analytics',
		required: ['transcribe'],
	},
	// #endregion

	// #region AUDIO
	{
		value: 'music-detect',
		label: 'Music detection',
		enabled: true,
		required: [],
		group: 'audio-analytics',
	},
	{
		value: 'audio-quality',
		label: 'Audio quality',
		enabled: true,
		required: [],
		group: 'audio-analytics',
	},
	{
		value: 'vocal-emotion',
		label: 'Vocal emotion',
		enabled: true, // false
		required: [],
		group: 'audio-analytics',
	},
	{
		value: 'music-copyright',
		label: 'Music copyright',
		enabled: true, // false
		required: [],
		group: 'audio-analytics',
	},
	// #endregion

	// #region VIDEO/IMAGE analytics
	{
		value: 'thumbnail-insights',
		label: 'Thumbnail insights',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	{
		value: 'scene-extraction',
		label: 'Scene extraction',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	{
		value: 'person-identification',
		label: 'Person identification',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	{
		value: 'facial-emotion',
		label: 'Facial emotion',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	{
		value: 'action-analysis',
		label: 'Action analysis',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	{
		value: 'text-isolation',
		label: 'Text isolation',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	{
		value: 'logo-detection',
		label: 'Logo detection',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	{
		value: 'text-detection',
		label: 'Text detection',
		enabled: true, // false
		group: 'video/image-analytics',
		required: [],
	},
	// #endregion
];

enum INITIALIZATION_STATUSES_ENUM {
	processing = 'processing',
	ready = 'ready',
	generating = 'generating',
	timeout = 'timeout',
	cancelled = 'cancelled',
	failed = 'failed',
}

const VIDEO_INSIGHTS_ACTIONS = [
	'Brand mention sentence/time isolation',
	'Brand sentiment at time identified',
	'MetaData',
	'Video summary',
	'Topic analysis',
	'Entity extraction',
	'Video transcription',
	'Text overlay',
	'Brand logo detection',
];

export {
	WHISPER_ACTIONS,
	INITIALIZATION_STATUSES_ENUM,
	VIDEO_INSIGHTS_ACTIONS,
	CDN_URL,
	NODE_API_URL,
	GO_API_URL,
};
