import { useContext } from 'react';

import {
	NotificationOutlined,
	TeamOutlined,
	UserAddOutlined,
} from '@ant-design/icons';
import { Button, Flex, Rate, Tooltip, Typography, notification } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import { useGetReportIdByPlatformHandle } from '@/api/batch_process';
import integrityReportImg from '@/lib/assets/integrityReport.png';
import placeholderImg from '@/lib/assets/placeholder.png';
import { formatNumber } from '@/lib/helpers/formatNumber';
import prepareImageSrc from '@/lib/helpers/prepareImageSrc';

import styles from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import { mockData } from '../data';

type TProps = {
	activeTab: TActiveMenuTab;
	item: any;
	onClose: () => void;
};

type TCreatorData = typeof mockData.creator;

export default function CreatorInsights({ activeTab, item, onClose }: TProps) {
	const { subscriptions } = useContext(SubscriptionContext);
	const navigate = useNavigate();
	const [api, contextHolder] = notification.useNotification();

	const creatorData: TCreatorData = item?.creator;

	const platformData = Object.values(creatorData?.platforms ?? {})?.[0];
	const postsAmount = formatNumber(platformData?.posts?.posts ?? 0);

	const followersAmount = formatNumber(
		(platformData?.connections?.followers ||
			platformData?.connections?.subscribers) ??
			0,
	);
	const followingAmount = formatNumber(
		platformData?.connections?.following ?? 0,
	);

	const {
		data,
		isLoading: isReportIdLoading,
		isSuccess,
		isError,
	} = useGetReportIdByPlatformHandle(platformData?.url, !!platformData?.url);

	const openNotification = () =>
		api.info({
			message: 'Influencer Integrity Report',
			description:
				'You are not subscribed to this product - would you like to find out more?',
			duration: 0,
			btn: (
				<Flex gap={8}>
					<Button onClick={() => api.destroy()}>Cancel</Button>
					<Button
						type="primary"
						onClick={() => {
							navigate(`/app/dashboard/subscription`);
							api.destroy();
						}}
					>
						Subscribe
					</Button>
				</Flex>
			),
		});

	const onIntegrityReportClick = () => {
		const hasActiveIntegrityReportSubscription = subscriptions?.some(
			(subscription) =>
				subscription?.subscription_name
					?.toLowerCase()
					?.includes('integrity report') && subscription?.active,
		);

		if (hasActiveIntegrityReportSubscription) {
			if (data?.data?.report_id) {
				navigate(`/app/dashboard/creators/${data?.data?.report_id}/report-v2`);
			} else {
				onClose();
				navigate(`/app/dashboard/creators?platformHandle=${platformData?.url}`);
			}
		} else {
			openNotification();
		}
	};

	if (activeTab !== 'creator-insight') {
		return null;
	}

	return (
		<>
			{contextHolder}
			<Flex vertical gap={4} align="flex-start">
				{
					<>
						<Flex
							style={{ width: '100%', marginBottom: '1rem' }}
							justify="space-between"
							align="center"
						>
							<Flex gap={8} align="center">
								{item?.creator?.profileImageURL ? (
									<img
										src={prepareImageSrc(item?.creator?.profileImageURL)}
										alt={item?.creator?.profileName}
										className={styles.thumbnail}
										width={100}
										height={100}
										onError={(e) => {
											// @ts-ignore
											e.target.src = placeholderImg; // Fallback image
										}}
									/>
								) : (
									<img
										src={placeholderImg}
										width={130}
										height={124}
										className={styles.thumbnail}
									/>
								)}

								{/* <Flex vertical gap={4} style={{ width: "100%" }}> */}
								{/* <Typography.Text>
            <b>Name:</b> {data?.creator?.profileName}
          </Typography.Text> */}

								{platformData?.url ? (
									<Tooltip title={item?.creator?.profileName}>
										<Button
											type="link"
											size="large"
											href={platformData?.url}
											target="_blank"
											style={{
												width: 'fit-content',
												fontSize: '18px',
												display: 'flex',
												wordWrap: 'break-word',
												// overflow: 'hidden',
												padding: '2px',
											}}
										>
											<span
												style={{
													width: '100%',
													fontSize: '18px',
													display: 'inline-block',
													// overflow: 'hidden',
													// textOverflow: 'ellipsis',
												}}
											>
												{item?.creator?.profileName}
											</span>
										</Button>
									</Tooltip>
								) : (
									<Tooltip title={item?.creator?.profileName}>
										<Typography.Text
											style={{
												width: 'fit-content',
												fontSize: '18px',
												display: 'inline-block',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
											}}
										>
											{platformData?.username}
										</Typography.Text>
									</Tooltip>
								)}
								{/* </Flex> */}
							</Flex>

							<Tooltip title="Integrity Report">
								<Button
									onClick={onIntegrityReportClick}
									style={{ height: 'fit-content', maxHeight: 'unset' }}
								>
									<Flex
										style={{ width: '100%', cursor: 'pointer' }}
										gap={4}
										align="center"
										justify="space-between"
									>
										<img
											src={integrityReportImg}
											alt=""
											style={{ height: '32px', width: '32px' }}
											height={32}
											width={32}
										/>

										<Flex vertical style={{ maxWidth: '100px' }} align="center">
											<Typography.Text style={{ fontSize: '12px' }}>
												Integrity Report
											</Typography.Text>
											<Rate
												defaultValue={4}
												disabled
												style={{ transform: 'scale(0.5)' }}
											/>
										</Flex>
									</Flex>
								</Button>
							</Tooltip>
						</Flex>
						{platformData && (
							<>
								<Typography.Text
									title={`${
										platformData?.connections?.followers ||
										platformData?.connections?.subscribers
									} followers`}
								>
									<TeamOutlined className={styles.amountItemIcon} />
									<span className={styles.amountItem}>
										{followersAmount}
									</span>{' '}
									<small>followers</small>
								</Typography.Text>

								{platformData?.connections?.following && (
									<Typography.Text
										title={`${platformData?.connections?.following} posts`}
									>
										<UserAddOutlined className={styles.amountItemIcon} />
										<span className={styles.amountItem}>
											{followingAmount}
										</span>{' '}
										<small>following</small>
									</Typography.Text>
								)}

								{platformData?.posts?.posts && (
									<Typography.Text
										title={`${platformData?.posts?.posts} posts`}
									>
										<NotificationOutlined className={styles.amountItemIcon} />
										<span className={styles.amountItem}>
											{postsAmount}
										</span>{' '}
										<small>posts</small>
									</Typography.Text>
								)}
							</>
						)}

						<Typography.Title
							level={4}
							className={cn(styles.tabTitle, styles.underline, styles.mt2)}
						>
							Self introduction
						</Typography.Title>

						{platformData?.description && (
							<Typography.Text>{platformData?.description}</Typography.Text>
						)}

						{platformData?.about &&
							platformData?.about !== platformData?.description && (
								<Typography.Text>{platformData?.about}</Typography.Text>
							)}
					</>
				}
			</Flex>
		</>
	);
}
