function removeHTMLTags(str: string) {
  return str.replace(/<\/?[^>]+(>|$)/g, "");
}

function decodeHtmlEntities(str: string) {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(str, "text/html").body
    .textContent;
  return decodedString;
}

function prepareComment(str: string) {
  if (typeof str !== "string") {
    return "";
  }

  return decodeHtmlEntities(removeHTMLTags(str));
}

export { removeHTMLTags, decodeHtmlEntities, prepareComment };
