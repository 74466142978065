import React, { useEffect } from 'react';

import { Spin } from 'antd';

import './style-radio.css';

import CurentSubscription from './CurentSubscriptionDetails';
import NewProductSubscription from './NewProductSubscription';
import SubscriptionPlans from './SubscriptionForms';
// import SubscriptionPlans from './SubscriptionForms';
import { useSubscription } from './useSubscription';

interface IProps {
	children?: React.ReactNode;
}

const SubscriptionLayout = (props: IProps) => {
	const { isSubscriptionsValid, subscription, isLoading, refetch } =
		useSubscription();

	useEffect(() => {
		refetch();
	}, []);

	if (isLoading) {
		return (
			<div className={`container subscription-wrapper`}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<Spin />{' '}
				</div>
			</div>
		);
	}
	if (subscription) {
		return (
			<div className={`container subscription-wrapper`}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
					}}
				>
					<CurentSubscription
						subscriptions={subscription}
						refetchSubscriptions={refetch}
					/>
				</div>
			</div>
		);
	}

	// return <SubscriptionPlans />;
	return <NewProductSubscription currentSubscriptions={subscription ?? []} />;
};

export default SubscriptionLayout;
