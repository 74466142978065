import { useContext, useEffect, useState } from 'react';

import { Button, Flex, Form, Modal, Typography, message } from 'antd';
import { Rate } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { SubscriptionContext } from '@/App';
import { useSendFeedback } from '@/api/batch_process';
import feedbackImg from '@/lib/assets/feedback_orange.png';

import styles from './styles.module.css';

type TProps = {
	url: string;
	openModal: boolean;
	setOpenModal: (value: boolean) => void;
};

export interface IResponseError {
	response: {
		data: {
			message: string;
		};
	};
	message: string;
}

const TEXTES = {
	feedback_title: 'Feedback form',
	feedback_text: `We really appreciate your help in improving our service. Be honest, and
        let us know the good the bad and the ugly, we won't be offended, pinky
        promise 😊`,
	feedback_placeholder:
		'Start here - let us know your ideas, comments and suggestions, everything is very welcome...',
	feedback_vote: 'My vote',
	feedback_send: 'Send feedback!',
	feedback_text_message: 'Please provide your feedback',
	feedback_text_empty_message: 'Haha) Your feedback is still empty',

	feedback_text_unexpected_subscription:
		'Something went wrong: active subscription with API key not found... Please contact us',

	feedback_rate_message: 'Please provide rate',
	feedback_thankyou_message: 'Thanks for your feedback!',

	cancel: 'Cancel',
};

export default function FeedBackModal({
	url = '',
	setOpenModal,
	openModal,
}: TProps) {
	const { subscriptions } = useContext(SubscriptionContext);
	const [form] = Form.useForm();
	const { mutate, data, isSuccess, isError, error, isLoading } =
		useSendFeedback();

	useEffect(() => {
		if (isSuccess) {
			form.resetFields();
			message.success(TEXTES.feedback_thankyou_message);
			setOpenModal(false);
		}

		if (isError) {
			const addError = error as IResponseError;

			message.error(addError?.response?.data?.message ?? addError?.message);
		}
	}, [isError, isSuccess]);

	const handleCancel = () => {
		form.resetFields();
		setOpenModal(false);
	};

	const handleOk = async () => {
		try {
			await form.validateFields();

			const { rate, feedback } = form.getFieldsValue();

			const activeSubscriptionWithApiKey = subscriptions?.find(
				(subscription) => subscription?.active && subscription?.api_key,
			);

			if (!activeSubscriptionWithApiKey) {
				message.warning(TEXTES.feedback_text_unexpected_subscription);
				return;
			}

			if (feedback?.trim()?.length === 0) {
				message.warning(TEXTES.feedback_text_empty_message);
				return;
			}

			// setLoading(true);

			mutate({
				feedback,
				rate,
				user_id: activeSubscriptionWithApiKey?.customer_id,
				url,
				api_key: activeSubscriptionWithApiKey?.api_key!,
			});

			// goApiClient
			// 	.post(
			// 		`/copilot/review`,
			// 		{
			// 			comment: feedback,
			// 			vote: rate,
			// 			user_id: Number(localStorage.getItem('sv_extension_uid')),
			// 			content_url: url ?? null,
			// 			// section: "Summary",
			// 		},
			// 		{
			// 			headers: {
			// 				Authorization: `Bearer ${token}`,
			// 			},
			// 		},
			// 	)
			// 	.then((res: any) => {
			// 		console.log('response', res);
			// 		if (res?.data?.code === 201 || res?.data?.code === 200) {
			// 			form.resetFields();
			// 			message.success(TEXTES.feedback_thankyou_message);
			// 			setOpenModal(false);
			// 		}
			// 	})
			// 	.catch((err: any) => {
			// 		console.log('feedback post err', err);
			// 		message.error(
			// 			`Something went wrong. Please try again in a moment.`,
			// 			// "Something went wrong: ", err
			// 		);
			// 	})
			// .finally(() => setLoading(false));
		} catch (error) {
			console.log(error);
			// message.error("Something went wrong: ", (error as any)?.toString());
		}
	};

	return (
		<Modal
			centered
			// loading={loading}
			width="500px"
			// height="100%"
			open={openModal}
			onOk={() => setOpenModal(false)}
			footer={null}
			closable={false}
			maskClosable={false}
			onCancel={handleCancel}
		>
			<Form
				form={form}
				initialValues={{
					feedback: '',
					rate: null,
				}}
				style={{ marginBottom: '1rem' }}
			>
				<Flex vertical gap={16}>
					<Typography.Title level={3} className={styles.title}>
						<img
							src={feedbackImg}
							width={50}
							height={40}
							className={styles.image}
						/>
						{TEXTES.feedback_title}
					</Typography.Title>

					<Typography.Text>{TEXTES.feedback_text}</Typography.Text>

					<Form.Item
						name="feedback"
						rules={[
							{
								required: true,
								message: TEXTES.feedback_text_message,
							},
						]}
						style={{ margin: 0 }}
					>
						<TextArea
							rows={10}
							placeholder={TEXTES.feedback_placeholder}
							style={{ resize: 'none' }}
						>
							{TEXTES.feedback_placeholder}
						</TextArea>
					</Form.Item>

					<Flex justify="flex-end" align="center" gap={16}>
						<Typography.Text>{TEXTES.feedback_vote}</Typography.Text>
						<Form.Item
							name="rate"
							rules={[
								{
									required: true,
									message: TEXTES.feedback_rate_message,
								},
							]}
							style={{ margin: 0 }}
						>
							<Rate allowHalf allowClear />
						</Form.Item>
					</Flex>
				</Flex>
			</Form>
			<Flex justify="flex-end" align="center" gap={8}>
				<Button onClick={handleCancel} disabled={isLoading}>
					{TEXTES.cancel}
				</Button>
				<Button
					type="primary"
					key="submit"
					onClick={handleOk}
					disabled={isLoading}
					loading={isLoading}
				>
					{TEXTES.feedback_send}
				</Button>
			</Flex>
		</Modal>
	);
}
