import { useContext } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import client from '@/lib/http/client';
import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

import { ClientExtendedSubscription, subscription } from '../subscriptionType';
import { productItems } from './data';

type TProps = {
	currentSubscriptions: ClientExtendedSubscription[];
};

export default function NewProductSubscription({
	currentSubscriptions,
}: TProps) {
	const fetchSubscriptions = async () => {
		return await client.get<subscription[]>(`/subscriptions`);
	};

	const { data: subscriptions, isLoading } = useQuery({
		queryKey: ['subscriptions2'],
		queryFn: async () => await fetchSubscriptions(),
		cacheTime: 0,
	});

	const { openDrawer, setSelectedSubscriptionsType } = useContext(PageContext);

	return (
		<Spin spinning={isLoading} style={{ width: '100%' }}>
			<div
				style={{
					width: '100%',
					maxWidth: '1360px',
					display: 'flex',
					flexGrow: 1,
				}}
			>
				<ul className={style.buttonLinksWrapper}>
					{productItems.map((item, idx) => {
						const isAlreadyInSubscriptions = currentSubscriptions?.some(
							(current) =>
								current?.type
									?.toLowerCase()
									?.includes(item?.group!.toLowerCase()),
						);

						return (
							<li
								key={item.id + idx}
								style={{ borderColor: item.borderColor }}
								className={cn(
									style.listItem,
									isAlreadyInSubscriptions && style.faded,
								)}
								onClick={
									() => {
										setSelectedSubscriptionsType(
											subscriptions?.data
												? subscriptions.data.filter(
														(allSubs) => allSubs.type === item.group,
												  )
												: [],
										);

										openDrawer('view-product-subscription', item);
									}
									// !isAlreadyInSubscriptions &&
								}
							>
								{/* <Link to={item.path} className={style.link}> */}
								<div className={style.link}>
									<div
										className={style.imageWrapper}
										style={
											item.group === 'report'
												? {
														borderRight: `1px solid ${item.borderColor}`,
														// maxWidth: '150px',
												  }
												: { borderRight: `1px solid ${item.borderColor}` }
										}
									>
										<img
											src={item.image}
											className={style.image}
											style={
												item.group === 'video'
													? {
															transform:
																'translate(7px,7px) scale(1.4) rotateZ(-45deg)',
															objectFit: 'contain',
													  }
													: item.group === 'co-pilot'
													? {
															transform: 'scale(0.75)',
													  }
													: {}
											}
										/>
									</div>

									<div className={style.textWrapper}>
										<p
											className={style.text}
											style={
												item.group === 'datalake' ? { maxWidth: '110px' } : {}
											}
										>
											{item.title}
										</p>

										{item.subtitle && (
											<span className={style.subtitleText}>
												{item.subtitle}
											</span>
										)}
									</div>
								</div>
								{/* </Link> */}
								{isAlreadyInSubscriptions && (
									<div className={style.alreadySubscribedBadge}>
										<span
											style={{
												fontSize: '12px',
												marginRight: '4px',
											}}
										>
											✓
										</span>{' '}
										Already subscribed
									</div>
								)}
							</li>
						);
					})}
				</ul>
			</div>
		</Spin>
	);
}
