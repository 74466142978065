import {
	FolderViewOutlined,
	LikeOutlined,
	ProductOutlined,
	SplitCellsOutlined,
} from '@ant-design/icons';
import { Flex, List, Typography } from 'antd';

import ActionCollapsableItem from '@/components/common/ActionCollapsableItem';
import VideoPlayer from '@/components/common/VideoPlayer';
import useTabVideoPlayer from '@/hooks/useTabVideoPlayer';
import placeholderImg from '@/lib/assets/placeholder.png';

import styles from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import { mockData } from '../data';

type TProps = {
	item: any;
	activeTab: TActiveMenuTab;
};

type TVideoData = typeof mockData.videoData;

export default function Actions({ activeTab, item }: TProps) {
	const { wrapperRef, playerRef, isPlayerVisible, seekToTime } =
		useTabVideoPlayer(activeTab, 'actions');

	if (activeTab !== 'actions') {
		return null;
	}

	const videoData: TVideoData = item?.videoData;

	return (
		<Flex
			vertical
			gap={12}
			align="flex-start"
			style={{ paddingLeft: '0.5rem' }}
		>
			<>
				{
					<>
						<Typography.Text className={styles.actionsTitle}>
							<FolderViewOutlined className={styles.actionsIcon} />
							Viewer suggested actions
						</Typography.Text>
						{videoData?.ViewerActions?.length > 0 ? (
							<List
								itemLayout="horizontal"
								className={styles.actionsList}
								dataSource={videoData?.ViewerActions ?? []}
								renderItem={(item: any, idx) => (
									<ActionCollapsableItem
										item={item}
										key={'viewer-action-item-' + idx}
										videoSeekToTime={seekToTime}
									/>
								)}
							/>
						) : (
							<span style={{ color: 'gray' }}>n/a</span>
						)}
					</>
				}

				<>
					<Typography.Text className={styles.actionsTitle}>
						<ProductOutlined className={styles.actionsIcon} />
						Brand/Entity suggested actions
					</Typography.Text>
					{videoData?.EntityActions?.length > 0 ? (
						<List
							itemLayout="horizontal"
							className={styles.actionsList}
							dataSource={videoData?.EntityActions ?? []}
							renderItem={(item: any, idx) => (
								<ActionCollapsableItem
									item={item}
									key={'brand-action-item-' + idx}
									videoSeekToTime={seekToTime}
								/>
							)}
						/>
					) : (
						<span style={{ color: 'gray' }}>n/a</span>
					)}
				</>

				<>
					<Typography.Text className={styles.actionsTitle}>
						<SplitCellsOutlined className={styles.actionsIcon} />
						Potential controversial issues
					</Typography.Text>
					{videoData?.ControversialTopics?.length === 0 ? (
						<Typography.Text className={styles.noIssuesLabel}>
							<LikeOutlined className={styles.noIssuesIcon} /> No issues
							detected
						</Typography.Text>
					) : videoData?.ControversialTopics ? (
						<List
							itemLayout="horizontal"
							className={styles.actionsList}
							dataSource={videoData?.ControversialTopics ?? []}
							renderItem={(item: any, idx) => (
								<ActionCollapsableItem
									item={item}
									key={'controversial-action-item-' + idx}
									// isPotentialContorversial
									videoSeekToTime={seekToTime}
								/>
							)}
						/>
					) : (
						<span style={{ color: 'gray' }}>n/a</span>
					)}
				</>

				{item?.cdnUrl && isPlayerVisible && (
					<Flex
						align="center"
						justify="center"
						style={{ width: '100%' }}
						ref={wrapperRef}
					>
						<VideoPlayer
							playerRef={playerRef}
							preview={
								!item?.video?.thumbnail.includes('.cdninstagram.')
									? item?.video?.thumbnail
									: placeholderImg
							}
							url={item?.cdnUrl}
						/>
					</Flex>
				)}
			</>
		</Flex>
	);
}
