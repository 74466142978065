import { Flex, List, Typography } from 'antd';
import cn from 'classnames';

import FeedBackButton from '@/components/common/FeedBackButton';
import InsightCollapsableItem from '@/components/common/InsightCollapsableItem';
import VideoPlayer from '@/components/common/VideoPlayer';
import useTabVideoPlayer from '@/hooks/useTabVideoPlayer';
import placeholderImg from '@/lib/assets/placeholder.png';

import styles from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import { mockData } from '../data';

type TProps = {
	data?: any;
	activeTab: TActiveMenuTab;
	item: any;
	setOpenModal: any;
};

type TVideoData = typeof mockData.videoData;

export default function KeyInsights({ activeTab, item, setOpenModal }: TProps) {
	const { wrapperRef, playerRef, isPlayerVisible, seekToTime } =
		useTabVideoPlayer(activeTab, 'key-insights');

	if (activeTab !== 'key-insights') {
		return null;
	}

	const videoData: TVideoData = item?.videoData;

	return (
		<>
			<Typography.Text
				className={cn(styles.text, !videoData?.Summary && styles.grayText)}
			>
				{!videoData?.Summary ? 'n/a' : videoData?.Summary}
			</Typography.Text>

			<>
				{videoData?.Insights?.length > 0 && (
					<>
						<Flex
							style={{ width: '100%' }}
							align="center"
							justify="space-between"
						>
							<Typography.Title
								level={4}
								className={cn(
									styles.tabTitle,
									// , styles.mb0, styles.mt2
								)}
							>
								<span className={styles.underline}>Takeaways</span>
							</Typography.Title>
							<FeedBackButton setOpenModal={setOpenModal} />
						</Flex>

						<ul
							style={{
								listStyleType: 'none',
								paddingLeft: 0,
								display: 'flex',
								flexDirection: 'column',
								gap: '1rem',
							}}
						>
							{videoData?.Insights?.map((item, idx) => (
								<li key={'item+' + idx}>
									<Typography.Title
										level={4}
										style={{ margin: 0, fontSize: '14px' }}
									>
										{item?.Title}
									</Typography.Title>

									<List
										itemLayout="horizontal"
										className={styles.keyInsights}
										dataSource={item.Details}
										renderItem={(li, index) => (
											<InsightCollapsableItem
												item={li}
												key={'insight-item' + index}
												videoSeekToTime={seekToTime}
											/>
										)}
									/>
								</li>
							))}
						</ul>

						{item?.cdnUrl && isPlayerVisible && (
							<Flex
								align="center"
								justify="center"
								style={{ width: '100%' }}
								ref={wrapperRef}
							>
								<VideoPlayer
									playerRef={playerRef}
									preview={
										!item?.video?.thumbnail.includes('.cdninstagram.')
											? item?.video?.thumbnail
											: placeholderImg
									}
									url={item?.cdnUrl}
									// isInstagramCdn={item?.video?.thumbnail.includes(
									//   ".cdninstagram."
									// )}
									// hasImg={!item?.video?.thumbnail}
									// isSmall
								/>
							</Flex>
						)}
					</>
				)}
			</>
		</>
	);
}
