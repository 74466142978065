import { createContext, useState } from 'react';

import { IDrawerProps, TDrawer } from '@/hooks';
import useDrawer, { IModalProps, TModals, useModal } from '@/hooks/useDrawer';
import { subscription } from '@/layouts/subscription/subscriptionType';

interface ProductSubscriptionProps {
	type: string;
	subscriptions: subscription[];
}

interface IPageProvider {
	isCreditsCounterVisible: boolean;
	setIsCreditsCounterVisible: React.Dispatch<boolean>;
	isCopilotCreditsCounterVisible: boolean;
	setIsCopilotCreditsCounterVisible: React.Dispatch<boolean>;
	pageTitle: string;
	setPageTitle: React.Dispatch<string>;
	drawer: IDrawerProps;
	isDrawerOpen: boolean;
	openDrawer: (type: TDrawer, data?: any) => void;
	closeDrawer: () => void;
	modal: IModalProps;
	isModalOpen: boolean;
	openModal: (type: TModals, data?: any) => void;
	closeModal: () => void;
	selectedSubscriptionsType: subscription[];
	setSelectedSubscriptionsType: React.Dispatch<subscription[]>;
}

export const PageContext = createContext<IPageProvider>({} as IPageProvider);

type TProps = {
	children: React.ReactNode[];
};

export const PageContextProvider = ({ children }: TProps) => {
	const [isCreditsCounterVisible, setIsCreditsCounterVisible] = useState(true);
	const [isCopilotCreditsCounterVisible, setIsCopilotCreditsCounterVisible] =
		useState(false);
	const [selectedSubscriptionsType, setSelectedSubscriptionsType] = useState<
		subscription[]
	>([]);

	const [pageTitle, setPageTitle] = useState('');
	const { drawer, isOpen: isDrawerOpen, openDrawer, closeDrawer } = useDrawer();

	const { modal, isOpen: isModalOpen, openModal, closeModal } = useModal();

	return (
		<PageContext.Provider
			value={{
				isCreditsCounterVisible,
				setIsCreditsCounterVisible,
				isCopilotCreditsCounterVisible,
				setIsCopilotCreditsCounterVisible,
				pageTitle,
				setPageTitle,
				drawer,
				isDrawerOpen,
				openDrawer,
				closeDrawer,
				modal,
				isModalOpen,
				openModal,
				closeModal,
				selectedSubscriptionsType,
				setSelectedSubscriptionsType,
			}}
		>
			{children}
		</PageContext.Provider>
	);
};
