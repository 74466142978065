import { Flex, List, Typography } from 'antd';

import TopicItem from '@/components/common/TopicItem';
import VideoPlayer from '@/components/common/VideoPlayer';
import useTabVideoPlayer from '@/hooks/useTabVideoPlayer';
import placeholderImg from '@/lib/assets/placeholder.png';

import styles from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import { mockData } from '../data';

type TProps = {
	data?: any;
	activeTab: TActiveMenuTab;
	item: any;
};

type TVideoData = typeof mockData.videoData;

export default function Topics({ activeTab, item }: TProps) {
	const videoData: TVideoData = item?.videoData;
	const { wrapperRef, playerRef, isPlayerVisible, seekToTime } =
		useTabVideoPlayer(activeTab, 'topics');

	if (activeTab !== 'topics') {
		return null;
	}

	return (
		<Flex vertical gap={12} align="flex-start">
			<>
				<ul
					style={{
						listStyleType: 'none',
						paddingLeft: '0.5rem',
						display: 'flex',
						flexDirection: 'column',
						gap: '1rem',
						width: 'calc(100% - 1rem)',
						margin: 0,
					}}
				>
					{videoData?.Topics?.length > 0 ? (
						videoData?.Topics?.map((item, idx) => (
							<li key={'item+' + idx}>
								<Typography.Title
									level={4}
									style={{ margin: 0, fontSize: '14px' }}
								>
									{item?.Title}
								</Typography.Title>

								<List
									itemLayout="horizontal"
									className={styles.keyInsights}
									dataSource={item.Summaries}
									renderItem={(li, index) => (
										<TopicItem
											item={li}
											key={'insight-item' + index}
											videoSeekToTime={seekToTime}
										/>
									)}
								/>
							</li>
						))
					) : (
						<span style={{ color: 'gray' }}>n/a</span>
					)}
				</ul>

				{item?.cdnUrl && isPlayerVisible && (
					<Flex
						align="center"
						justify="center"
						style={{ width: '100%' }}
						ref={wrapperRef}
					>
						<VideoPlayer
							playerRef={playerRef}
							preview={
								!item?.video?.thumbnail.includes('.cdninstagram.')
									? item?.video?.thumbnail
									: placeholderImg
							}
							url={item?.cdnUrl}
							// isInstagramCdn={item?.video?.thumbnail.includes(".cdninstagram.")}
							// hasImg={!item?.video?.thumbnail}
							// isSmall
						/>
					</Flex>
				)}
			</>
		</Flex>
	);
}
