import React, { useContext } from 'react';

import { Button, Checkbox, Modal, Spin } from 'antd';

import { SubscriptionContext } from '@/App';
import { PageContext } from '@/providers/PageContextProvider';

import { useSubscription, useUnsubscribe } from './useSubscription';

function UnsubscribeModal() {
	const [checked, setChecked] = React.useState(false);

	const { modal, closeModal, openModal, isModalOpen } = useContext(PageContext);
	const [loading, setLoading] = React.useState(false);
	const { setUnsubscribeSubscription, unsubscribeSubscription } =
		useContext(SubscriptionContext);
	const { isSubscriptionsValid, subscription, isLoading, refetch } =
		useSubscription();

	const mutation = useUnsubscribe();

	const handleUnsubscribe = async () => {
		if (unsubscribeSubscription) {
			setLoading(true);

			mutation.mutate(unsubscribeSubscription.subscription_id, {
				onError: (error: any) => {
					setLoading(false);
					closeModal();
					// notification.error({
					// 	message: 'Error',
					// 	description: `Failed to add ifluencer ${record.username} to queue.`,
					// });
					// setErrorMessage("Something went wrong");
				},
				onSuccess: () => {
					refetch();
					setLoading(false);
					closeModal();
					// setButtonInActive(true);
					// notification.success({
					// 	message: 'Success',
					// 	description: `Influencer ${record.username} added successfully to queue.`,
					// });
				},
			});
		}
	};

	return (
		<>
			<Modal
				destroyOnClose
				open={modal.isOpen && modal.type === 'unsubscribe'}
				title="Are you sure?"
				footer={null}
				onCancel={() => {
					closeModal();
					setChecked(false);
				}}
			>
				<Spin spinning={loading} size="large">
					<div>
						<p>
							When you cancel your monthly subscription, you won't be able to
							start a new subscription until all your top-up(s) or available
							report(s) have expired, which happens on the last day of your
							current subscription{' '}
							<span>
								{unsubscribeSubscription?.subscription_end_date
									? new Date(
											unsubscribeSubscription.subscription_end_date,
									  ).toLocaleDateString('en-GB', {
											day: '2-digit',
											month: 'short',
											year: 'numeric',
									  })
									: 'N/A'}
							</span>
							. Any unused top-up(s) reports or remaining report(s) will be
							lost. Are you sure you want to proceed?
						</p>
						{/* Вставляем футер внутрь Spin */}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Checkbox
								checked={checked}
								onChange={(e) => setChecked(e.target.checked)}
							>
								I have read and understood the terms and conditions.
							</Checkbox>
							<div
								style={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									margin: '16px 0',
								}}
							>
								<Button
									key="back"
									onClick={() => {
										setChecked(false);
										closeModal();
									}}
									disabled={loading}
								>
									Cancel
								</Button>
								<Button
									key="submit"
									type="default"
									disabled={!checked || loading}
									onClick={handleUnsubscribe}
									style={{ color: checked ? 'red' : '' }}
								>
									Unsubscribe
								</Button>
							</div>
						</div>
					</div>
				</Spin>
			</Modal>
		</>
	);
}

export default UnsubscribeModal;
