import React, { useContext } from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Flex, Form } from 'antd';
import { subscribe } from 'diagnostics_channel';
import { useNavigate } from 'react-router-dom';

import { SubscriptionContext } from '@/App';
import { PageContext } from '@/providers/PageContextProvider';

import style from './style.module.css';

import { subsriptionIcons } from './SubscriptionIcons';
import { ClientSubscription, subscription } from './subscriptionType';

type SubscriptionCartFormItemProps = {
	subscription: subscription; // replace Subscription with the actual type
	index: number;
	subscribe: boolean;
	subscriptionId: number;
	isAnySubscribeActive: boolean;
	matchedSubscription?: ClientSubscription;
	activeSubscription: ClientSubscription | any;
};

function SubscriptionCartFormItemVariants({
	subscription,
	index,
	subscribe,
	subscriptionId,
	isAnySubscribeActive,
	matchedSubscription,
	activeSubscription,
}: SubscriptionCartFormItemProps) {
	const navigate = useNavigate();
	const [TopUpSubscrition, setTopUpSubscrition] =
		React.useState<ClientSubscription | null>(null);
	const [openUnsubModal, setUnsubModal] = React.useState(false);
	const { closeDrawer, openModal } = useContext(PageContext);
	const { setUnsubscribeSubscription } = useContext(SubscriptionContext);
	const onFinish = (
		subscription: subscription,
		changePlan: boolean = false,
	) => {
		closeDrawer();
		navigate('/app/subscribe', { state: { subscription, changePlan } });
		// Here you can send the subscriptionId to the backend
	};
	const Id = Number(subscription.id);

	const IconComponent = subsriptionIcons[subscriptionId]
		? subsriptionIcons[subscriptionId]
		: subsriptionIcons[1];

	return (
		<div
			key={index}
			style={{
				transform: 'scale(0.9)',
				flex: '1 1 0',
				width: '100%',
			}}
		>
			<Card
				title={subscription.subscription_name}
				bordered={false}
				className={subscribe ? style.andtCard : style.andtCardUnsubscribe}
				// className="222"
				// style={{
				// 	transition: 'box-shadow 0.3s ease, transform 0.2s ease',
				// 	border: '1px solid #ddd',
				// }}
				// onMouseEnter={(e) => {
				// 	e.currentTarget.style.boxShadow =
				// 		'0 0 20px 5px rgba(255, 165, 0, 0.7)';
				// 	e.currentTarget.style.transform = 'scale(1.02)';
				// }}
			>
				<Form
					name={`subscription_form_${index}`}
					onFinish={(values) => onFinish(subscription)}
					className={style.andtForm}
				>
					<div>
						<p style={{ textAlign: 'justify', margin: 0 }}>
							<IconComponent
								style={{
									float: 'left',
									margin: '0 10px 10px 0',
									width: '200px',
								}}
							/>
							{subscription.description}
						</p>
					</div>

					<div>
						<div style={{ display: 'flex', margin: '20px 0' }}>
							<ul
								style={{
									width: '100%',
									textAlign: 'left',
									fontSize: '0.9rem',
									listStyleType: 'none',
									paddingLeft: '12px',
									marginTop: 0,
									marginBottom: 0,
								}}
							>
								<li key={subscription?.id + index} className={style.includes}>
									<CheckCircleOutlined className={style.checkicon} />
									<p style={{ margin: 'unset' }}>
										Reports per month: {subscription.item_count_per_month}
									</p>
								</li>
								{subscription.item_count_per_month !== 1 && (
									<li
										key={subscription?.id + index + 'item_count_per_month'}
										className={style.includes}
									>
										<CheckCircleOutlined className={style.checkicon} />
										<p style={{ margin: 'unset' }}>
											Cost per report: $
											{(
												subscription.per_month_eur /
												subscription.item_count_per_month
											).toFixed(2)}
										</p>
									</li>
								)}
								{subscription.top_up_eur !== null && (
									<li
										key={subscription?.id + index + 'top_up_eur'}
										className={style.includes}
									>
										<CheckCircleOutlined className={style.checkicon} />
										<p style={{ margin: 'unset' }}>
											Additional Top-up (per report): ${' '}
											{subscription.top_up_eur}
										</p>
									</li>
								)}
								{/* {subscription.features.map((feature, index) => (
                    <li key={index}>
                        <CheckCircleOutlined style={{ marginRight: '10px' }} />
                        {feature}
                    </li>
                ))} */}
							</ul>
							<div
								style={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<span style={{ fontSize: '1.9rem' }}>
									$ {subscription.per_month_eur}
								</span>
								<p
									style={{
										textAlign: 'left',
										display: 'inlineBlock',
										maxWidth: 'min-content',
										fontSize: '0.9rem',

										marginLeft: '8px',
										lineHeight: 'normal',
									}}
								>
									per month
								</p>
							</div>
						</div>
						{subscribe && !isAnySubscribeActive && (
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									size="large"
									className={style.subscribeButton}
									style={{ width: '100%' }}
								>
									Subscribe
								</Button>
							</Form.Item>
						)}
						{!subscribe && matchedSubscription && (
							<Form.Item>
								<Button
									color="danger"
									variant="outlined"
									type="default"
									htmlType="button"
									size="large"
									className={style.subscribeButton}
									style={{ width: '100%' }}
									// icon={<EditOutlined style={{ fontSize: '12px' }} />}
									onClick={() => {
										setUnsubscribeSubscription(matchedSubscription);
										openModal('unsubscribe');
									}}
								>
									Unsubscribe
								</Button>
							</Form.Item>
						)}

						{subscribe && isAnySubscribeActive && (
							<Form.Item>
								<Button
									type="primary"
									htmlType="button"
									size="large"
									className={style.subscribeButton}
									style={{ width: '100%' }}
									onClick={() => {
										onFinish(subscription, true);
										// setUnsubscribeSubscription(matchedSubscription);
										// openModal('unsubscribe');
									}}
								>
									{activeSubscription
										? subscription.per_month_usd <
										  activeSubscription.per_month_usd
											? 'Downgrade'
											: 'Upgrade '
										: 'Downgrade'}
								</Button>
							</Form.Item>
						)}
					</div>
				</Form>
			</Card>
		</div>
	);
}

export default SubscriptionCartFormItemVariants;
