import { useContext, useEffect, useRef, useState } from 'react';

import { CommentOutlined } from '@ant-design/icons';
import {
	Descriptions,
	Drawer,
	Flex,
	Space,
	Table,
	Tabs,
	Tag,
	Tooltip,
	Typography,
	message,
} from 'antd';
import cn from 'classnames';
import ReactPlayer from 'react-player';

import { useGetSingleCopilotVideo } from '@/api/batch_process';
import FeedBackButton from '@/components/common/FeedBackButton';
import FeedBackModal from '@/components/common/FeedBackModal';
import TabsControls from '@/components/common/TabsControls';
import { TTitle, titles } from '@/components/common/TabsControls/data';
import robotImage from '@/lib/assets/co-pilot.png';
import { formatNumber } from '@/lib/helpers/formatNumber';
import { PageContext } from '@/providers/PageContextProvider';

import styles from './styles.module.css';

import { columns } from './components/columns';
import Actions from './components/tabs/Actions';
import BrandsEntities from './components/tabs/BrandsEntities';
import Comments from './components/tabs/Comments';
import CreatorInsights from './components/tabs/CreatorInsights';
import KeyInsights from './components/tabs/KeyInsights';
import Topics from './components/tabs/Topics';
import Transcript from './components/tabs/Transcript';
import VideoInsights from './components/tabs/VideoInsights';

export type TActiveMenuTab =
	| 'key-insights'
	| 'creator-insight'
	| 'video-insight'
	| 'entity-insights'
	| 'comments'
	| 'actions'
	| 'topics'
	| 'brands-entities'
	| 'transcript';

export default function ViewCopilotVideoDetailsDrawer() {
	const playerRef = useRef<ReactPlayer>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const { drawer, closeDrawer } = useContext(PageContext);
	const [openModal, setOpenModal] = useState(false);
	const [search, setSearch] = useState('');
	const [activeMenuTab, setActiveMenuTab] =
		useState<TActiveMenuTab>('key-insights');

	const {
		data: responseData,
		isError,
		error,
		isLoading,
		isFetching,
	} = useGetSingleCopilotVideo(drawer?.data?.url, !!drawer?.data?.url);

	const onClose = () => {
		// form.resetFields();
		setOpenModal(false);
		closeDrawer();
		setActiveMenuTab('key-insights');
	};

	const videoSeekToTime = (seconds: number) => {
		wrapperRef.current &&
			wrapperRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		playerRef.current && playerRef.current.seekTo(seconds, 'seconds');
	};

	useEffect(() => {
		if (isError) {
			const getError = error as any;

			message.error({
				type: 'error',
				content: `Something went wrong: ${
					getError?.response?.data?.message ?? getError?.message
				}`,
			});
		}
	}, [isError]);

	const data = responseData?.data?.data;

	return (
		<Drawer
			open={drawer.isOpen && drawer.type === 'view-copilot-video-details'}
			width={'740px'}
			onClose={onClose}
			closable
			title={
				<Flex gap={16} align="center">
					<img
						src={robotImage}
						alt="Product image"
						width={60}
						height={'auto'}
						style={{ borderRadius: '10px', overflow: 'hidden' }}
					/>
					<Typography.Title level={2}>Copilot video details</Typography.Title>
				</Flex>
			}
			loading={isLoading || isFetching}
		>
			<Flex vertical gap={10} justify={'center'} style={{ width: '100%' }}>
				<Flex className={styles.contentWrapper} vertical>
					<div className={styles.textWrapper}>
						<TabsControls setActiveMenuTab={setActiveMenuTab} />

						<Flex
							style={{
								width: '100%',
								flexGrow: 1,
								// , paddingTop: '36px'
							}}
						>
							<Flex
								vertical
								gap={10}
								justify={'center'}
								style={{ width: '100%' }}
							>
								<Flex className={styles.contentWrapper}>
									<Flex
										className={cn(styles.textWrapper, styles.rightSideWrapper)}
										vertical
									>
										<div className={styles.scrollableWrapper2}>
											{activeMenuTab !== 'video-insight' && (
												<Typography.Title level={4} className={styles.tabTitle}>
													<Flex gap={30} justify="space-between" align="center">
														<Flex
															gap={30}
															justify="space-between"
															align="center"
														>
															<span className={styles.underline}>
																{titles[activeMenuTab as TTitle]}
															</span>

															{activeMenuTab === 'comments' &&
																data?.video?.stats?.comments >= 0 && (
																	<Tooltip
																		title={`Total comments ${data?.video?.stats?.comments}`}
																	>
																		<Flex gap={8}>
																			<CommentOutlined />
																			<span
																				className={styles.totalCommentsCount}
																			>
																				{formatNumber(
																					data?.video?.stats?.comments,
																				)}
																			</span>
																		</Flex>
																	</Tooltip>
																)}
														</Flex>

														<FeedBackButton setOpenModal={setOpenModal} />
													</Flex>
												</Typography.Title>
											)}
											<Transcript
												search={search}
												setSearch={setSearch}
												activeTab={activeMenuTab}
												item={data}
											/>
											<KeyInsights
												activeTab={activeMenuTab}
												item={data}
												setOpenModal={setOpenModal}
											/>
											<CreatorInsights
												activeTab={activeMenuTab}
												item={data}
												onClose={onClose}
											/>
											<VideoInsights
												activeTab={activeMenuTab}
												playerRef={playerRef}
												videoSeekToTime={videoSeekToTime}
												videoWrapperRef={wrapperRef}
												item={data}
												setOpenModal={setOpenModal}
											/>
											<Comments activeTab={activeMenuTab} item={data} />
											<Actions activeTab={activeMenuTab} item={data} />
											<BrandsEntities activeTab={activeMenuTab} item={data} />
											<Topics activeTab={activeMenuTab} item={data} />
										</div>
									</Flex>
								</Flex>
							</Flex>
						</Flex>
					</div>
				</Flex>

				{/* </Flex> */}
			</Flex>
			<FeedBackModal
				url={drawer?.data?.url}
				openModal={openModal}
				setOpenModal={setOpenModal}
			/>
		</Drawer>
	);
}
