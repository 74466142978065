import { Button, List } from 'antd';

import getSentimentBadge from '@/lib/helpers/getSentimentBadgeColor';
import getTimeInSeconds from '@/lib/helpers/getTimeInSeconds';

type TProps = {
	item: any;
	videoSeekToTime: (seconds: number) => void;
};

export default function SentimentItem({ item, videoSeekToTime }: TProps) {
	const seconds = getTimeInSeconds(item?.Timestamp);

	return (
		<List.Item>
			<List.Item.Meta
				avatar={
					<span
						style={{
							width: '10px',
							height: '10px',
							borderRadius: '50%',
							display: 'block',
							paddingTop: '5px',
						}}
					>
						{getSentimentBadge(item?.Sentiment)}
					</span>
				}
				title={
					<span
						style={{
							color: '#000',
							fontWeight: 500,
						}}
					>
						{seconds !== null && (
							<Button
								type="link"
								style={{
									padding: '2px 2px',
									marginRight: '4px',
								}}
								onClick={() => {
									// playerRef?.current?.seekTo(seconds, "seconds");
									// videoWrapperRef?.current?.scrollIntoView({
									//   block: "start",
									//   behavior: "smooth",
									// });
									videoSeekToTime(seconds);
								}}
							>
								{item?.Timestamp}
							</Button>
						)}

						{item?.Extract}
					</span>
				}
			/>
		</List.Item>
	);
}
