import { Flex, List } from 'antd';

import BrandEntityCollapsableItem from '@/components/common/BrandEntityCollapsableItem';
import VideoPlayer from '@/components/common/VideoPlayer';
import useTabVideoPlayer from '@/hooks/useTabVideoPlayer';
import placeholderImg from '@/lib/assets/placeholder.png';

import styles from '../../styles.module.css';

import { TActiveMenuTab } from '../..';
import { mockData } from '../data';

type TProps = {
	data?: any;
	item: any;
	activeTab: TActiveMenuTab;
};

type TVideoData = typeof mockData.videoData;

export default function BrandsEntities({ activeTab, item }: TProps) {
	const videoData: TVideoData = item?.videoData;

	const { wrapperRef, playerRef, isPlayerVisible, seekToTime } =
		useTabVideoPlayer(activeTab, 'brands-entities');

	if (activeTab !== 'brands-entities') {
		return null;
	}

	return (
		<Flex vertical gap={4} align="flex-start" style={{ width: '100%' }}>
			{
				<>
					<Flex
						vertical
						key={'entity-items'}
						style={{ width: '100%' }}
						// style={{ width: '100%', paddingLeft: '0.5rem' }}
					>
						{/* <Typography.Title level={4} className={styles.brandEntityTitle}>
                  {entity?.Name}
                </Typography.Title> */}
						{videoData?.Entities?.length > 0 ? (
							<List
								itemLayout="horizontal"
								className={styles.keyInsights}
								dataSource={videoData?.Entities ?? []}
								renderItem={(item: any, index) => (
									<BrandEntityCollapsableItem
										item={item}
										key={'BrandEntityCollapsableItem' + index}
										videoSeekToTime={seekToTime}
									/>
								)}
							/>
						) : (
							<span style={{ color: 'gray' }}>n/a</span>
						)}
					</Flex>
					{item?.cdnUrl && isPlayerVisible && (
						<Flex
							align="center"
							justify="center"
							style={{ width: '100%' }}
							ref={wrapperRef}
						>
							<VideoPlayer
								playerRef={playerRef}
								preview={
									!item?.video?.thumbnail.includes('.cdninstagram.')
										? item?.video?.thumbnail
										: placeholderImg
								}
								url={item?.cdnUrl}
								// isInstagramCdn={item?.video?.thumbnail.includes(
								// 	'.cdninstagram.',
								// )}
								// hasImg={!item?.video?.thumbnail}
								// isSmall
							/>
						</Flex>
					)}
				</>
			}
		</Flex>
	);
}
